@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700&display=swap');

:root{
  --blue: #007e9b;
  --black: #000;
  --grey: #ccc;
  --red: red;
  --gold: gold;
  --orange: orange;
  --ultra-light-grey: #f3f3f3;
  --white: #FFF;
  --primary-font: "Montserrat", sans-serif;
  --primary-font-size: 18px;
}

/* General Styles */
* { margin: 0; padding: 0; box-sizing: border-box;}
html { font-family:  var(--primary-font); font-weight: 300; font-size: var(--primary-font-size);}
body { min-height: 100vh; color: var(--black);}
/* .App { min-height: 100vh; display: flex;} */

a, a:active, a:visited { color: var(--blue);}
h1 { text-transform: lowercase;}

h1 { margin-bottom: 20px;}
#cameras h4 { font-weight: normal;}

strong { font-weight: bold;}
.updatesPopup .right { text-align: right;}
.updatesPopup .salutaion { margin-top: 20px;}

header { display: flex; position: fixed; z-index: 9; width: 100%;}

header #passHeadingWrapper {flex: 1 0 35%; background: var(--white); padding: 20px; display: flex; align-items: center;}
header #passHeadingWrapper .refreshData { margin-left: 20px;}
header #passHeadingWrapper .refreshData svg { position: relative; top: 3px; fill: var(--blue); font-size: 1.5em; cursor: pointer;}
header #passHeadingWrapper .refreshData.updating svg { animation: spin 1s linear infinite;}
@keyframes spin {
    0% { transform: rotate(0deg);}
    100% { transform: rotate(360deg);}
}

header #passHeadingWrapper .refreshData svg:hover { fill: var(--black); }
header #navigationWrapper {display: flex; flex-direction: column; text-align: right; flex: 0 0 10%; padding: 20px; background: var(--white);}
header #secondaryNavigationWrapper {flex: 0 0 55%; display: flex; justify-content: end; padding-right: 20px;}
header main { display: flex; flex: 0 0 45%; justify-content: space-between;}

header button { margin-top: 0 !important; padding: 0;}
header .logo { display: flex; align-items: center; text-decoration: none;}
header .logo .icon { position: relative}
header .logo svg { width: 50px; position: absolute; top: 0; left: 0;}
header .logo span { font-style: normal; height: 50px; width: 50px; text-align: center; display: inline-block; line-height: 50px; color: #FFF; position: relative; font-size: 24px;}
header .logo h1 { color: var(--black); font-size: 24px; padding: 0 0 0 15px; margin: 0; display: inline-block; text-transform: lowercase;}

footer { display: none;}

section.contentWrapper { width: 100%; height: 100%; position: absolute; top: 0; left: 0; display: flex;}
section.primary { flex: 0 0 45%; padding: 100px 20px 20px 20px; display: flex; flex-wrap: wrap;}
/* section.primary:not(#home) { overflow-y: scroll;} */
section.secondary { flex: 0 0 55%; display: flex; flex-wrap: wrap; flex-direction: column; background-image: url('assets/images/drive_colorado_bg.jpg'); background-size: cover; background-position: center center;}
section.flex { flex: 1; background-image: url('assets/images/drive_colorado_bg.jpg'); background-size: cover; background-position: bottom center; }
section.overview { background: var(--black); color: var(--white); padding: 20px; max-height: 75%;}
section.overview h3 { font-size: 26px;}

#home section.primary { align-content: center; overflow: hidden; position: relative;}
#home section.primary #key { position: absolute; bottom: 20px; left: 20px; font-size: .7em; font-style: italic;}
#home section.primary #key p { display: flex; align-content: center; flex-direction: row; margin-top: 5px;}
#home section.primary #key .icon { width: 20px; height: 20px; background: var(--black); margin-right: 5px; border-radius: 50%; position: relative; top: -2px; flex: 0 0 20px;}
#home section.primary #key .icon.cdot { background: var(--blue);}
#home section.primary #key .icon.user { background: orange;}
#home section.primary h2 { flex: 0 0 100%; font-size: 6vw; text-transform: lowercase;}
#home section.primary h2.open { font-size: 12vw; line-height: 1.2;}
#home section.primary h2.closed { font-size: 10vw; line-height: 1;}
#home section.primary h2 .userReported { color: orange; text-decoration: none;}
section.overview article:not(:last-of-type) { margin-bottom: 20px;}
section.overview a { color: var(--white);}
section.overview article ul { list-style: none;}
section.overview article ul li { margin-bottom: 20px; display: flex;}
section.overview h3 { margin-bottom: 20px;}

button.menu__button { background: none; border: none; cursor: pointer; position: relative; z-index: 99; flex: 0 0 20%; text-align: right; font-size: 32px;}
button.menu__button svg { height: 40px; fill: var(--black);}
body.menu__active button.menu__button svg { fill: var(--white);}
body.menu__active button.menu__button:hover { background: none; color: var(--blue);}
button.menu__button:hover { background: none;}
button.menu__button:hover svg { fill: var(--blue);}

section.menu__overlay { display: none;}
body.menu__active section.menu__overlay { display: flex; position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; background: rgba(0,0,0,.9); z-index: 9;}
body.menu__active nav { padding-top: 100px; width: 45%; padding-right: 20px; display: flex; flex-direction: column; opacity: 1; }
body nav .menu__link { position: relative; right: -20px; opacity: 0;  line-height: 50px; font-size: 32px;}
body.menu__active nav .menu__link { position: relative; right: -20px; }
/* body.menu__active nav .menu__link:nth-of-type(2) { transition-delay: .1s;}
body.menu__active nav .menu__link:nth-of-type(3) { transition-delay: .2s;}
body.menu__active nav .menu__link:nth-of-type(4) { transition-delay: .3s;}
body.menu__active nav .menu__link:nth-of-type(5) { transition-delay: .4s;}
body.menu__active nav .menu__link:nth-of-type(6) { transition-delay: .5s;}
body.menu__active nav .menu__link:nth-of-type(7) { transition-delay: .6s;}
body.menu__active nav .menu__link:nth-of-type(8) { transition-delay: .7s;}
body.menu__active nav .menu__link:nth-of-type(9) { transition-delay: .8s;} */

section.menu__overlay nav a { text-decoration: none; color: var(--white);}
section.menu__overlay nav a.active { color: var(--blue);}

/* Conditions */
ul.conditions { list-style: none; display: flex; flex-wrap: wrap;}
ul.conditions li { display: flex; flex-direction: column; align-items: center; width: 75px;}
ul.conditions li:not(:last-of-type) { margin-right: 10px;}
ul.conditions li svg { width: 75px; fill: var(--blue);}
ul.conditions li span:not(.MuiBadge-root) { text-align: center; opacity: 0; transition: ease-in-out all .5s; font-weight: bold;}
ul.conditions li:hover span { opacity: 1; transition: ease-in-out all .5s; }
ul.conditions li:hover svg { fill: var(--black);}

/* User Reported Conditions */
ul.conditions li.userReported { cursor: pointer;}
ul.conditions li.userReported svg { fill: orange;}
ul.conditions li.userReported .wrapper { display: block; position: relative;}
ul.conditions li.userReported .wrapper .confirmation { display: flex; justify-content: space-between; position: absolute; left: 0; bottom: 0; width: 100%;}
ul.conditions li.userReported .wrapper .confirmation div { background: var(--white); border-radius: 50%; width: 30px; height: 30px; display: flex; justify-content: center; align-items: center;}
ul.conditions li.userReported .wrapper .confirmation svg { width: auto;}
ul.conditions li.userReported .wrapper .confirmation div:hover svg { fill: var(--black);}
.confirmation .up svg { fill: green;}
.confirmation .down svg { fill: var(--red);}
.MuiBadge-badge { background: var(--red) !important; color: var(--white) !important; opacity: 1 !important;}
button.cancel { background: var(--grey);}
button.cancel:hover { background: var(--black) !important;}
button.confirm:hover { background: var(--black) !important;}
p.reportNote { padding: 10px; border: 1px solid var(--blue); margin-top: 20px; color: var(--blue);}
p.clearNote { padding: 10px; border: 1px solid var(--blue); margin-bottom: 20px; color: var(--blue);}

ul.conditions li.addReport svg { width: 75px; fill: var(--black); height: 75px; cursor: pointer;}

nav#levelTwo button.road-open { background: green;}
nav#levelTwo button.road-closed { background: var(--red);}
nav#levelTwo button.light { background: green;}
nav#levelTwo button.moderate { background: orange;}
nav#levelTwo button.heavy { background: var(--red);}
nav#levelTwo button.stand-still { background: black;}
nav#levelTwo button.stop-and-go { background: midnightblue;}
nav#levelTwo button.lane-closure { background: var(--red);}
nav#levelTwo button.general { background: orange;}
nav#levelTwo button.periodic-stops { background: black;}

/* Forms */
form { display: flex; flex-direction: column; justify-content: flex-start; flex-grow: 1; padding-bottom: 1rem; width: 50%;}
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
button,
textarea { font-family: 'Fira Sans', sans-serif; font-size: 22px; padding: 0.25rem;}

form label { font-weight: 700; font-size: 18px; margin-bottom: 5px;}
form input:not([type=checkbox]) { height: 50px; margin-bottom: 10px;}
form input, form textarea { background: #f7f7f7; border: 1px solid #c4c4c4; border-radius: 0; box-shadow: none; color: #787878; font-weight: 300; width: 100%; padding-right: 60px;}
form input:focus, form textarea:focus { background: var(--white); border: 1px solid #919191; box-shadow: none; outline: none;}
form input:focus-visible, form textarea:focus-visible { background: var(--white); border: 1px solid #919191; box-shadow: none; outline: none;}
form .inputWrapper { position: relative;}
form .valid, form .invalid { position: absolute; right: 0; top: 0; height: 50px; width: 50px; text-align: center; line-height: 57px;}
form input.error, form textarea.error { border: 1px solid var(--red);}
form span.required { color: var(--red);}
p.required { font-size: .8em; text-align: right; font-style: italic; width: 100%;}
p.required span { color: var(--red);}
form input#persist { width: 20px;}

form select { background: #f7f7f7; border: 1px solid #c4c4c4; border-radius: 0; width: 100%; height: 50px; line-height: 50px; color: #787878; font-weight: 300; font-size: 22px; font-family: var(--primary-font); padding-left: 10px;}

label, button { margin-top: 1rem;}
button { padding: 0.5rem; background: var(--blue); border: none; color: var(--white);}
a.button { padding: 0.5rem; background: var(--blue); border: none; color: var(--white); text-decoration: none; margin-top: 20px; display: block; width: 50%; text-align: center;}
a.button:hover { background: var(--black);}
section button:hover:not(:disabled) { background: var(--grey);}
button:disabled { opacity: .5; cursor: not-allowed;}
textarea { resize: vertical;}

/* Error Messages */
.instructions { font-size: 0.75rem; color: var(--red); position: relative; bottom: 10px;}
.instructions > svg { margin-right: 0.25rem;}
.offscreen { position: absolute; left: -9999px;}
.hide { display: none;}
.valid { background: limegreen; color: var(--white); margin-left: 0.25rem;}
.invalid { background: var(--red); color: var(--white); margin-left: 0.25rem;}
.errmsg { background-color: var(--red); color: var(--white); font-weight: bold; padding: 0.5rem; margin-bottom: 0.5rem;}
.line { display: inline-block;}

/* Maintenance Mode */
#maintenance { flex: 0 0 100%; overflow: hidden; justify-content: center; align-content: center; flex-direction: column; text-align: center;}

/* Email Verification */
#emailVerification form { width: 100%;}

/* User Notification */
p.notification { border: 1px solid red; padding: 20px; margin-bottom: 20px; display: flex; align-items: center;}
p.notification svg { margin-right: 20px;}

/* Loading Page */
#loading { flex: 0 0 100%; overflow: hidden; justify-content: center; align-content: center; flex-direction: column; text-align: center;}

/* Login/Signup */
#login section.primary { display: block; overflow-y: scroll;}
#login section.primary article { }
#login section.primary article form { width: 75%;}

/* Create Report */
.createReport #category { display: flex; flex-wrap: wrap; gap: 10px;}
.createReport #category.selected button { display: none;}
.createReport #category.selected button.active { display: block;}
.createReport #category.selected button.back { display: block;}
.createReport button { background: var(--blue); color: var(--white); border: none; font-size: 22px; padding: 20px 0px; flex: 0 0 calc(50% - 5px); text-transform: uppercase; cursor: pointer;}
.createReport button svg { position: relative; top: 4px; margin-right: 10px;}

.createReport #cateogry button:hover { background: var(--black);}
.createReport .parent nav { display: none;}
.createReport .parent:hover { background: var(--black);}
.createReport .parent.active { flex: 0 0 100%;}
.createReport .parent.active nav { display: block;}
.createReport .parent.active { background: none; color: var(--black); padding: 0;}
.createReport .parent.active nav#levelTwo button { display: block;}
.createReport .parent.active nav#levelTwo button:hover { background: var(--black);}
.createReport .parent.active nav#levelTwo { display: flex; flex-wrap: wrap; gap: 10px;}
.createReport button.back { flex: 0 0 100%; background: var(--grey);}
.createReport button.close { flex: 0 0 100%; background: var(--grey);}

/* History */
#history {}
#history hr { margin: 20px 0;}
#history h3, #history h2 { margin-bottom: 20px;}
#history h4 { text-transform: capitalize;}
#history ul { list-style: none;}
#history strong { font-weight: bold;}
#history section.primary { display: block; overflow-y: scroll;}
#history .status { padding-bottom: 20px; overflow-y: hidden;}
#history .status .icon { height: 20px; width: 20px; border-radius: 50%; background: black; display: inline-block; float: left; margin-right: 10px; position: relative;}
#history .status .icon:after { content: ''; width: 1px; height: 100px; position: absolute; left: 10px; top: 20px; display: inline-block; background: #ccc;}
#history .status.last .icon:after { display: none;}
#history .status .icon.closed { background: red;}
#history .status .icon.open { background: green;}
#history .status p { margin-left: 30px;}

/* Cameras */
#cameras {}
#cameras h2 { margin-bottom: 20px;}
#cameras section.primary { overflow-y: scroll;}
#cameras h4 { font-size: .7em;}
#cameras ul { display: flex; flex-wrap: wrap; gap: 20px; list-style: none;}
#cameras ul li { flex: 0 0 calc((100% / 3) - (40px / 3)); display: flex; flex-direction: column; justify-content: end;}
#cameras ul li img { width: 100%; aspect-ratio: 3/2;}
#cameraLightbox img { width: 100%;}

/* Alerts */
#alerts {}
#alerts h2 { margin-bottom: 20px;}
#alerts section.primary { display: block; overflow-y: scroll;}
#alerts section.primary article { margin-bottom: 20px;}
#alerts section.primary article ul { list-style: none;}
#alerts section.primary article ul li:not(:last-of-type) { margin-bottom: 20px; }
#alerts .content div:not(:last-of-type) { margin-bottom: 10px;}
#alerts .confirmation { display: flex;}
#alerts .confirmation div { margin-right: 20px; cursor: pointer;}
#alerts .events h4 { background: var(--grey); padding: 5px 20px; border-bottom: 1px solid var(--grey); display: flex; justify-content: space-between; color: var(--white); text-transform: uppercase;}
#alerts .event { border: 1px solid var(--grey);}
#alerts .event .content { padding: 20px;}
#alerts .incidents h4 { background: var(--grey); padding: 5px 20px; border-bottom: 1px solid var(--grey); display: flex; justify-content: space-between; color: var(--white); text-transform: uppercase;}
#alerts .incident { border: 1px solid var(--grey);}
#alerts .incident .content { padding: 20px;}
#alerts .marginBottom { margin-bottom: 20px;}

/* User Reports */
.userAlerts li { display: block !important;}
.userAlerts h4 { text-transform: capitalize;}
/* .userAlerts .icon { width: 20px; height: 20px; border-radius: 50%; display: inline-block; flex: 0 0 20px; background: var(--grey); position: relative; top: 2px;} */
/* .userAlerts .content { padding: 0 0 0 10px; } */
#alerts .userAlerts .reports { border: 1px solid var(--grey);}
#alerts .userAlerts .reports .content { padding: 20px;}
#alerts .userAlerts h4 { background: var(--grey); padding: 5px 20px; border-bottom: 1px solid var(--grey); display: flex; justify-content: space-between; color: var(--white); text-transform: uppercase;}
#alerts .userAlerts h4 span.icon { position: relative; top: 4px;}
#alerts .userAlerts h4.low { background: var(--gold);}
#alerts .userAlerts h4.medium { background: var(--orange);}
#alerts .userAlerts h4.high { background: var(--red);}
#alerts .userAlerts h4.light { background: green;}
#alerts .userAlerts h4.moderate { background: orange;}
#alerts .userAlerts h4.heavy { background: var(--red);}
#alerts .userAlerts h4.stand-still { background: black;}
#alerts .userAlerts h4.stop-and-go { background: midnightblue;}
#alerts .userAlerts h4.general { background: orange;}
#alerts .userAlerts h4.lane-closures { background: var(--red);}
#alerts .userAlerts h4.periodic-stops { background: black;}
#alerts .userAlerts h4.road-closed { background: var(--red);}
#alerts .userAlerts h4.road-open { background: green;}
#alerts .userAlerts .reports .confirmation {}
#alerts .userAlerts .reports .confirmation div { margin-right: 0; margin-left: 20px;}
#alerts .userAlerts .reports .confirmation div svg { fill: var(--white);}

/* Home Page User Reports */
#home ul.userAlerts li.reports { margin-bottom: 20px; display: flex !important;}
#home ul.userAlerts li.reports .content { padding: 0 0 0 10px;}
#home ul.userAlerts li.reports .icon { width: 20px; height: 20px; border-radius: 50%; display: inline-block; flex: 0 0 20px; background: var(--grey); position: relative; top: 2px;}

/* Traffic & Construction Icons */
.traffic .icon, .construction .icon { width: 20px; height: 20px; border-radius: 50%; display: inline-block; flex: 0 0 20px; background: var(--grey); position: relative; top: 2px;}
.traffic .content, .construction .content { padding: 0 0 0 10px; }
.icon.light { background: green !important;}
.icon.moderate { background: orange !important;}
.icon.heavy { background: var(--red) !important;}
.icon.stand-still { background: black !important;}
.icon.stop-and-go { background: midnightblue !important;}
.icon.general { background: orange !important;}
.icon.lane-closures { background: var(--red) !important;}
.icon.periodic-stops { background: black !important;}
.icon.road-closed { background: var(--red) !important;}

/* Incident & Event Icons */
.incident .icon, .event .icon { width: 20px; height: 20px; border-radius: 50%; display: inline-block; flex: 0 0 20px; background: var(--grey); position: relative; top: 2px;}
.incident .icon.chainLaw, .event .icon.chainLaw { background: cadetblue}
.incident .icon.tractionLaw, .event .icon.tractionLaw { background: blue;}
.incident .icon.roadMaintenance, .event .icon.roadMaintenance { background: orange;}
.incident .icon.construction, .event .icon.construction { background: orange;}
.incident .icon.closed, .event .icon.closed { background: red;}
.incident .content, .event .content { padding: 0 0 0 10px; }
#alerts h4.chainLaw { background: cadetblue; }
#alerts h4.tractionLaw { background: blue; }
#alerts h4.roadMaintenance { background: orange; }
#alerts h4.construction { background: orange; }
#alerts h4.closed { background: red; }
#alerts h4.avalanche { background: darkblue; }


/* News */
#news {}
#news .post_date { font-style: italic; font-size: .8em; margin-bottom: 10px; color: var(--blue);}
#news .author { margin-top: 10px; text-align: right;}
#news .author:before { content: '-';}
#news .content { white-space: pre-line; }
#news h2 { margin-bottom: 20px;}
#news section.primary { display: block; overflow-y: scroll;}
#news h4 {}
#news ul { list-style: none;}
#news ul li:not(:last-of-type) { margin-bottom: 20px; border-bottom: 1px solid var(--grey); padding-bottom: 20px;}

/* Error */
#error section.primary { display: block; overflow-y: scroll;}

/* Dashboard */
#dashboard section.primary { display: block; overflow-y: scroll;}

/* Settings */
#settings section.primary { display: block; overflow-y: scroll;}
#userReportedSettings { padding: 20px; border: 1px solid var(--grey);}
#userReportedSettings #outlined-number {}
#userReportedSettings #threshold-label{ margin-top: 0 !important;}
#userReportedSettings #conditions { margin-bottom: 30px;}
#settings form { width: 100%;}
#settings .bottomMargin { margin-bottom: 30px;}

/* Donate */
#donate section.primary { display: block; overflow-y: scroll;}
#donate section.primary p { margin-bottom: 20px;}

/* About */
#about section.primary { display: block; overflow-y: scroll;}
#about section.primary p:not(:last-of-type){ margin-bottom: 20px;}

/* Support */
#support section.primary { display: block; overflow-y: scroll;}
#support section.primary { display: block; overflow-y: scroll;}
#support section.primary form { width: 100%;}
#support section.primary p { margin-top: 20px;}
#support .supportNotice { display: flex; padding: 20px; background: var(--red); color: var(--white); align-items: center;}
#support .supportNotice svg { margin-right: 20px;}

/* Login not verified */
.notVerified { display: flex; padding: 20px; background: var(--red); color: var(--white); align-items: center; margin-top: 20px;}
.notVerified svg { margin-right: 20px;}

/* Other Passes */
#otherPasses section.primary { display: block; overflow-y: scroll;}
#otherPasses section.primary p:not(:last-of-type){ margin-bottom: 20px;}
#otherPasses section.primary ul { list-style: none;}

/* Notifications */
#notifications {}
#notifications h2 { margin-bottom: 20px;}
#notifications section.primary { display: block; overflow-y: scroll;}
#notifications h3 { background: var(--grey); padding: 5px 20px; border-bottom: 1px solid var(--grey); display: flex; justify-content: space-between; color: var(--white); text-transform: uppercase;}
#notifications h3 span.icon { position: relative; top: 4px;}
#notifications h3.low { background: var(--gold);}
#notifications h3.medium { background: var(--orange);}
#notifications h3.high { background: var(--red);}
#notifications ul { list-style: none;}
#notifications ul li { border: 1px solid var(--grey); margin-bottom: 20px;}
#notifications ul li .content { padding: 20px;}
#notifications ul li .content p.type { text-transform: capitalize;}
#notifications ul li .content p.importance { text-transform: capitalize;}
#notifications ul li .content strong { font-weight: bold;}
#notifications ul li .content p:not(:last-of-type) { margin-bottom: 10px;}

button.notifications { color: var(--black);}
button.notifications svg { font-size: .75em;}
button.notifications.importance1 .MuiAvatar-circular { color: gold !important;}
button.notifications.importance2 .MuiAvatar-circular { color: orange !important;}
button.notifications.importance3 .MuiAvatar-circular { color: red !important;}
button.notifications .MuiBadge-badge { background: var(--red) !important; color: var(--white) !important;}
#secondaryNavigationWrapper span.name { padding: 0px 25px; display: inline-block; line-height: 32px; height: 32px; background: var(--black); position: relative; right: -34px; cursor: pointer; color: var(--white); border-top-left-radius: 20px; border-bottom-left-radius: 20px;}
#secondaryNavigationWrapper span.name.importance1 { }
#secondaryNavigationWrapper span.name.importance2 { }
#secondaryNavigationWrapper span.name.importance3 { }
.notificationWrapper { display: flex; align-items: center;}

/* Weather */
#weather section.primary { display: block; overflow-y: scroll;}

/* Email Verification */
#emailVerification section.primary { display: block;}

/* Forgot Password */
#forgotPassword section.primary { display: block;}
#forgotPassword section.primary form { width: 100%;}

/* Reset Password */
#passwordReset section.primary { display: block;}
#passwordReset section.primary form { width: 100%;}

/* MUI Overrides */
.MuiAvatar-circular { background: var(--black) !important;}
.MuiIconButton-root:hover { background: var(--blue) !important;}
.MuiDialog-container header { background-color: var(--blue) !important;}
.MuiDialogActions-spacing { padding: 0 20px 20px 20px !important;}
div[role="tabpanel"] .MuiBox-root { padding: 20px 0 0 0 !important;}
/* .MuiTab-root.Mui-selected { color: var(--blue) !important;} */
#home .MuiTab-root { color: var(--white) !important;}
#home .MuiTab-root:hover { background: transparent !important; color: var(--grey) !important;}
#home .MuiTab-root.Mui-selected { color: var(--blue) !important;}
button[role="tab"]:hover { background: var(--ultra-light-grey) !important;}
#home .MuiTabs-root { margin-bottom: 20px !important;}
#home .MuiTabs-root .MuiBadge-root { margin-right: 20px !important;}
#alerts .MuiTabs-root .MuiBadge-root { margin-right: 20px !important;}

/* Loader */
.loader { height: 100px; display: flex;}

@-webkit-keyframes meetup {
  0%, 100% {
    -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
            transform: rotate(calc(var(--rotation) * 1deg)) translateY(0); }
  50% {
    -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
            transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%); } }
@keyframes meetup {
  0%, 100% {
    -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
            transform: rotate(calc(var(--rotation) * 1deg)) translateY(0); }
  50% {
    -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
            transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%); } }

.meetup {
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
  height: 10px;
  width: 10px;
  position: relative;
   margin: auto;
  }
  .meetup div {
    height: 100%;
    position: absolute;
    width: 100%;
    -webkit-animation: meetup 1.25s infinite ease;
            animation: meetup 1.25s infinite ease;
    background: var(--blue);
    border-radius: 100%; }
    .meetup div:nth-child(1) {
      --rotation: 90; }
    .meetup div:nth-child(2) {
      --rotation: 180; }
    .meetup div:nth-child(3) {
      --rotation: 270; }
    .meetup div:nth-child(4) {
      --rotation: 360; }

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }